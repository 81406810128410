@media print {
  @page {
    size: auto;
    margin: 0.25in 0.5in;
  }

  body {
    font-size: 10pt;
  }

  /* generic print utilities */
  .no-print {
    display: none !important;
  }

  .page-break {
    page-break-after: always !important;
  }

  /* arbitrary widgets we never want printed */
  .grv-widget-tag,
  #dev-button,
  #dev-panel {
    display: none !important;
  }

  #footer {
    display: none;
  }

  nav,
  a.button {
    display: none !important;
  }

  // hide select dropdown icon
  .select:not(.is-multiple):not(.is-loading) {
    &::after {
      display: none !important;
    }
    select {
      padding-right: $control-padding-horizontal !important;
    }
  }

  // hide arrows on number fields in firefox to match chrome
  input[type="number"] {
    -moz-appearance: textfield;
  }

  a {
    color: $grey-darker !important;
  }
}
