html[data-view="ingredient_metas#index_for_recipe"],
html[data-view="ingredient_metas#index_for_user"] {
  #floating-ingredient-list-preview {
    display: flex;
    justify-content: flex-end;

    position: sticky;
    top: 8px;
    margin-bottom: 8px;
    z-index: 5;

    &:not(.is-active) {
      #floating-ingredient-list {
        display: none;
      }
    }

    #floating-ingredient-list {
      border-color: $grey-dark;
    }
  }

  .ingredient-list-preview {
    background-color: $white;
    border: 1px solid $black;
    padding: 0.5em;
    text-transform: uppercase;
  }
}
