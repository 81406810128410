.control.has-show-password-button {
  a.show-password-button {
    pointer-events: initial;
    cursor: pointer;
    .icon {
      color: $grey-light;
      pointer-events: initial;
    }
  }

  &.password-is-shown {
    a.show-password-button .icon {
      color: $info;
    }
  }
}
