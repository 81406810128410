/* bulma-style buttons are not yet implemented */

/* classic-base buttons */
@keyframes download-spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Stand-alone page */
.stashed-file-single-page-spinner {
  .progress-spinner,
  .error-icon,
  .success-icon,
  .error-message,
  .progress-message,
  .download-link {
    display: none;
  }

  &.is-downloading-ready {
    .success-icon,
    .download-link {
      display: block;
    }
  }

  &.is-downloading {
    .progress-spinner {
      display: block;
    }
  }

  &.is-downloading.is-downloading-with-message {
    .progress-message {
      display: block;
    }
  }

  &.is-downloading-error {
    .error-icon,
    .error-message {
      display: block;
    }
  }
}

.classic-base {
  .btn.is-downloading {
    pointer-events: none;

    /* Hide existing glyphicons and caption, preserving the size */
    span,
    i[class*="icon"] {
      visibility: hidden;
    }

    position: relative;
    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: -8px;
      border-radius: 50%;
      border: 2px solid $white;
      border-top-color: transparent;
      animation: download-spinner 0.7s linear infinite;
    }
  }
}

#stashed-file-status-mirror {
  pointer-events: none;

  &::after {
    margin-left: 0.5em;
    @include loader;
  }
}
