html[data-view="recipes#breakdown"] {
  .table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .nutrition-breakdown-table {
    @extend .table;
    @extend .is-fullwidth;
    @extend .is-striped;
    @extend .is-hoverable;

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0;

      background-color: inherit;
      background-clip: padding-box;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-right: 1px solid $border;
      }
    }

    thead,
    tfoot {
      background-color: inherit;

      tr {
        background-color: inherit;
      }
    }

    tbody {
      background-color: inherit;

      tr {
        // is-striped and is-hover both set background colors, don't wish to overwrite
        &:not(:nth-child(2n)):not(:hover) {
          background-color: inherit;
        }
      }
    }
  }
}
