/*! purgecss start ignore */

$arrow-size: 0.4em;

th[role="columnheader"]:not([data-sort-method="none"]) {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &::after {
    width: 1.5em;
    display: inline-block;
    text-align: right;
    color: $grey-light;
    content: "⇅";

    @media print {
      display: none !important;
    }
  }

  &:hover::after {
    color: $grey;
  }
}

th[aria-sort="ascending"]:not([data-sort-method="none"])::after {
  content: "↑";
  color: $info !important;
}

th[aria-sort="descending"]:not([data-sort-method="none"])::after {
  content: "↓";
  color: $info !important;
}

/* Links in sortable THs for accessiblity.  Don't style like links */
th[role="columnheader"] > a {
  color: $black;
}

/*! purgecss end ignore */
