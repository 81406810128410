/* merriweather-300italic - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 300;
  src:
    local("Merriweather Light Italic"),
    local("Merriweather-LightItalic"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-300italic.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-regular - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  src:
    local("Merriweather Regular"),
    local("Merriweather-Regular"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-regular.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-italic - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  src:
    local("Merriweather Italic"),
    local("Merriweather-Italic"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-italic.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-300 - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 300;
  src:
    local("Merriweather Light"),
    local("Merriweather-Light"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-300.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-700 - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  src:
    local("Merriweather Bold"),
    local("Merriweather-Bold"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-700.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-700italic - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  src:
    local("Merriweather Bold Italic"),
    local("Merriweather-BoldItalic"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-700italic.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-900 - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  src:
    local("Merriweather Black"),
    local("Merriweather-Black"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-900.woff2") format("woff2");
  font-display: fallback;
}
/* merriweather-900italic - latin-ext_latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 900;
  src:
    local("Merriweather Black Italic"),
    local("Merriweather-BlackItalic"),
    url("../../fonts/merriweather/merriweather-v22-latin-ext_latin-900italic.woff2") format("woff2");
  font-display: fallback;
}
