.user-settings-page {
  min-height: fit-content;
  margin-bottom: 3rem;

  & #card-element {
    max-width: 32rem;
  }

  & h1.title {
    font-size: 1.5rem;

    @include from($tablet) {
      font-size: 2rem; // Same as normal .title
      margin-bottom: 3rem;
    }
  }

  // Settings sections are hidden by default, shown via JS when the corresponding link is clicked.
  & .settings-section {
    display: none;
  }

  & .settings-subsection {
    margin-bottom: 3rem;
  }

  & .current-members-subsection {
    margin-top: 1.5rem;
  }

  & .group-member {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  & .group-member-email {
    padding-left: 0.5rem;
    line-height: 1;

    // Scooting up 1px looks better aligned with the 'x' in the button icon
    margin-top: -1px;
  }

  & input {
    max-width: 40ch;
  }

  & hr {
    background-color: hsl(0, 0%, 86%);
  }

  & #sidebar {
    margin-right: 2rem;

    @include until($tablet) {
      display: none;
    }
  }

  & #mobile-menu {
    display: none;
    max-width: 42ch;

    @include until($tablet) {
      display: block;
    }
  }

  & .subtitle {
    margin-bottom: 0.5rem;
  }

  & p {
    margin-bottom: 1rem;
  }

  & .menu-list a {
    border-radius: 0.5rem;

    &:hover:not(.is-active) {
      background-color: #d9e7f2;
      color: unset;
    }
  }

  & .table {
    background-color: unset;
  }

  & .button.is-info.is-disabled {
    pointer-events: none;

    &:hover {
      @extend .is-info;
      background-color: unset;
    }
  }

  & textarea.textarea {
    min-width: unset;
    max-width: 40ch;
  }

  & #address-management {
    & .address {
      white-space: pre-wrap;
    }

    & .add-address {
      max-width: 40ch;
    }

    & #add-new-address {
      @include until($desktop) {
        float: right;
        width: 50%;
      }

      @include until($tablet) {
        width: 100%;
      }
    }

    & .add-another-address {
      // When it's not the first address, there's a table of addresses with the buttons on the right;
      // so in that case, we pull the add-new-address button and form to the right as well.
      margin-left: auto;

      & .control {
        // Align the submit button to the right.
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  & #api-key {
    margin-top: 1.25rem;

    & code {
      background: white;
      color: #c02020;
      width: fit-content;

      @include until($tablet) {
        font-size: 0.75rem;
      }
    }
  }
}
