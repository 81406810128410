#ingredient_show_voting {
  .voting-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0.75rem 0 0.75rem 0.2rem;

    .row {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .vote-column {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
    }

    .text {
      max-width: 21rem;
    }

    .has-icon {
      display: inline-flex;
    }

    .vote-score {
      margin: 0.2rem 0;
    }

    .checkmark {
      margin-left: 0.2rem;
    }
  }
}
