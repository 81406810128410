#undo-panel {
  position: fixed;
  left: 20px;
  bottom: 20px;
  max-width: 200em;
  z-index: 2;

  li {
    border-left: 4px solid $warning;
    background-color: $warning-light;
    color: $link;

    margin: map-get($map: $spacing-values, $key: "1");
    padding: map-get($map: $spacing-values, $key: "3");

    position: relative;

    a[data-close] {
      position: absolute;
      top: 0;
      right: 0;
      .icon {
        color: $grey;
      }
    }

    a[data-link] {
      color: $link;
      text-decoration: underline;
      margin-right: 2em;
      @include has-flex-centered($vertical: true);
    }
  }
}
