.tabs {
  .dropdown-content {
    a.dropdown-item {
      // These styles get overwritten by .is-active on the tab
      display: block;
      border: initial;
      border-radius: initial;
      color: $dropdown-item-color;

      &:hover {
        background-color: $dropdown-item-hover-background-color;
        color: $dropdown-item-hover-color;
      }

      &.is-active {
        background-color: $dropdown-item-active-background-color;
        color: $dropdown-item-active-color;
      }
    }
  }
}

section.is-subnav {
  nav.tabs.is-boxed {
    margin-left: -2px;

    li.is-active a:first-child {
      background-color: #f1f3f4;
    }

    max-width: 940px;
  }

  position: relative;
  z-index: 2;

  /* subnavs shouldn't be more responsive than classic-base, which is fixed width: It can lead to
     the tabs having a scrollbox independent of the page content.  If the user must pinch/zoom to
     operate, the two should at least be consistent.
  */
  & > .container {
    /* adjusted from 940px to account for .classic-base padding */
    width: 956px;
  }
}

section.is-subnav + section.classic-base.with-subnavs > .container > .sixteen.columns.roundelement {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

section.classic-base > .container > .sixteen.columns {
  position: relative;

  /* 960px classic grid width adjusted for margins, padding */
  width: 940px;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 1em;
  margin-top: 20px;

  border: 1px solid rgb(219, 219, 219);
}

section.classic-base.with-subnavs > .container > .sixteen.columns {
  margin-top: -1px;
}
