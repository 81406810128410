@import "jose_modern/css";

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  background-color: change-color($white, $alpha: 0.8);
  // mix-blend-mode: luminosity;

  display: flex !important; /* classic-base reset */
  justify-content: center;
  align-items: center;

  .spinning-element {
    color: $grey;
    width: 48px;
    height: 48px;

    display: flex !important;
    .icon {
      display: flex !important;
    }
  }
}

/* Special case for full-screen spinners */
body > .spinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
}
