.currency-change {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-basis: 0;
  column-gap: 0.5ch;

  .new-amount {
    order: 1;
  }

  .old-amount {
    display: none;
  }

  .diff {
    order: 0;
    font-size: $size-7;
    font-style: italic;
  }

  &.positive .diff {
    color: $green;
    &::before {
      content: "+";
    }
  }

  &.negative .diff {
    color: $red;
  }
}
