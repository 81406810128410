.content.testimonial {
  blockquote {
    background-color: $white;
    /* bespoke green */
    border-left-color: #5fcf7f;
    border-left-width: 4px;
    p {
      font-style: italic;
    }
  }

  span,
  strong {
    display: block;
    font-size: small;
  }

  span {
    text-transform: uppercase;
  }

  strong {
    font-family: $family-secondary;
  }
}
