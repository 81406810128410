/* lato-100 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src:
    local("Lato Hairline"),
    local("Lato-Hairline"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-100.woff2") format("woff2");
  font-display: fallback;
}
/* lato-100italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  src:
    local("Lato Hairline Italic"),
    local("Lato-HairlineItalic"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-100italic.woff2") format("woff2");
  font-display: fallback;
}
/* lato-300 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src:
    local("Lato Light"),
    local("Lato-Light"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-300.woff2") format("woff2");
  font-display: fallback;
}
/* lato-300italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src:
    local("Lato Light Italic"),
    local("Lato-LightItalic"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-300italic.woff2") format("woff2");
  font-display: fallback;
}
/* lato-regular - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src:
    local("Lato Regular"),
    local("Lato-Regular"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-regular.woff2") format("woff2");
  font-display: fallback;
}
/* lato-italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src:
    local("Lato Italic"),
    local("Lato-Italic"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-italic.woff2") format("woff2");
  font-display: fallback;
}
/* lato-700 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src:
    local("Lato Bold"),
    local("Lato-Bold"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-700.woff2") format("woff2");
  font-display: fallback;
}
/* lato-700italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src:
    local("Lato Bold Italic"),
    local("Lato-BoldItalic"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-700italic.woff2") format("woff2");
  font-display: fallback;
}
/* lato-900 - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src:
    local("Lato Black"),
    local("Lato-Black"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-900.woff2") format("woff2");
  font-display: fallback;
}
/* lato-900italic - latin-ext_latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src:
    local("Lato Black Italic"),
    local("Lato-BlackItalic"),
    url("../../fonts/lato/lato-v17-latin-ext_latin-900italic.woff2") format("woff2");
  font-display: fallback;
}
