$scroll-hint-duration: 1200ms;
$scroll-hint-scale: 2;

/* animate.css has a bounce, but not horizontal without an entrance or exit */
@keyframes bounce-x {
  0%,
  20%,
  53%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scale($scroll-hint-scale) translateX(0);
  }

  40%,
  43% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale($scroll-hint-scale) translateX(-30px);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale($scroll-hint-scale) translateX(-15px);
  }

  90% {
    transform: scale($scroll-hint-scale) translateX(0px);
  }
}

[data-scroll-hint] {
  position: relative;
}

.table-container[data-scroll-hint] {
  /* make horizontal scrolling harder to trigger a history navigation on supported browsers */
  overscroll-behavior-x: contain;
}

/* special handling for common case: the .scroll-hint-arrow adds margin */
.table-container[data-scroll-hint] .table {
  margin-bottom: 0;
}

[data-scroll-hint] .scroll-hint-arrow {
  overflow: hidden;
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  color: $info;
  transform-origin: top right;

  opacity: 0.2;
  transform: scale($scroll-hint-scale);

  &.bouncing {
    animation-duration: $scroll-hint-duration;
    animation-name: bounce-x;
    animation-fill-mode: forwards;
  }

  &.fading {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }

  @media print {
    display: none !important;
  }
}
