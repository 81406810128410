﻿.button.is-jumbo {
  font-size: $size-6;
  font-weight: bold;
  height: 4rem;

  @include tablet {
    min-width: 12em;
    padding-left: 2em;
    padding-right: 2em;
  }

  @include mobile {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
}

.button.is-mini {
  border-radius: 3px;
  font-size: $size-7;
  height: 22px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 1em;
  padding-right: 0.75em;

  span {
    height: 22px;
    line-height: 22px;

    &.icon {
      margin-top: -2px;
    }
  }

  @include touch {
    &.text-hidden-mobile {
      padding-right: 5px;

      span:not(.icon) {
        display: none;
      }

      span.icon {
        margin-top: -1px;
        margin-right: 0;
      }
    }
  }
}

.has-text-secondary {
  color: $secondary;
}

.has-text-weight-900 {
  font-weight: 900;
}

.is-super {
  vertical-align: super;
}

.button.is-fullwidth-mobile {
  @include touch {
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.select.is-fullwidth-mobile {
  @include touch {
    width: 100%;

    select {
      width: 100%;
    }
  }
}

.is-fullheight {
  height: 100%;
}

.is-fullwidth {
  width: 100%;
}

.button.is-borderless {
  border-color: transparent;
}

.rounded-right {
  border-top-right-radius: $radius-rounded;
  border-bottom-right-radius: $radius-rounded;
}

.well {
  background-color: darken($white, 2%);
  border: 1px solid darken($white, 10%);
  border-radius: $box-radius;
  padding: 1rem;
}

/* These extensions are bulma-dependent or bulma-like for common patterns we're using */
@mixin has-flex-centered($horizontal: false, $vertical: false) {
  display: flex !important;

  @if ($horizontal) {
    justify-content: center !important;
  }

  @if ($vertical) {
    align-items: center !important;
  }
}

.has-flex-centered {
  @include has-flex-centered($horizontal: true, $vertical: true);
}

.has-flex-vcentered {
  @include has-flex-centered($vertical: true);
}

.has-flex-vtop {
  display: flex !important;
  flex-direction: row;
  align-items: flex-start !important;
}

.has-flex-hcentered {
  @include has-flex-centered($horizontal: true);
}

@include mobile {
  .has-flex-centered-mobile {
    @include has-flex-centered($horizontal: true, $vertical: true);
  }

  .has-flex-hcentered-mobile {
    @include has-flex-centered($horizontal: true, $vertical: true);
  }

  .has-flex-vcentered-mobile {
    @include has-flex-centered($vertical: true);
  }
}

.has-flex-left {
  justify-content: flex-start !important;
}

.has-flex-right {
  justify-content: flex-end !important;
}

@include mobile {
  .has-flex-right-mobile {
    justify-content: flex-end !important;
  }
}

@include mobile {
  .is-flex-direction-column-mobile {
    flex-direction: column !important;
  }
}

/* margin helpers */
.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

@for $i from 0 through 6 {
  .gap-#{$i} {
    gap: map-get($spacing-values, "#{$i}");
  }

  .column-gap-#{$i} {
    column-gap: map-get($spacing-values, "#{$i}");
  }

  .row-gap-#{$i} {
    row-gap: map-get($spacing-values, "#{$i}");
  }
}

@for $i from 0 through 6 {
  .order-#{$i} {
    order: $i;
  }

  .order-neg-#{$i} {
    order: -$i;
  }

  @include mobile {
    .order-#{$i}-mobile {
      order: $i;
    }

    .order-neg-#{$i}-mobile {
      order: -$i;
    }
  }
}

/* It's pretty common for us to want different margins and paddings at the mobile breakpoint */
@include tablet {
  @for $i from 0 through 6 {
    $value: map-get($spacing-values, "#{$i}");

    .mt-#{$i}-tablet {
      margin-left: $value !important;
    }

    .ml-#{$i}-tablet {
      margin-top: $value !important;
    }

    .my-#{$i}-tablet {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    .mx-#{$i}-tablet {
      margin-left: $value !important;
      margin-right: $value !important;
    }

    .py-#{$i}-tablet {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }

    .px-#{$i}-tablet {
      padding-left: $value !important;
      padding-right: $value !important;
    }
  }
}

@include touch {
  @for $i from 0 through 6 {
    $value: map-get($spacing-values, "#{$i}");

    .mt-#{$i}-touch {
      margin-top: $value !important;
    }

    .my-#{$i}-touch {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    .mx-#{$i}-touch {
      margin-left: $value !important;
      margin-right: $value !important;
    }

    .py-#{$i}-touch {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }

    .px-#{$i}-touch {
      padding-left: $value !important;
      padding-right: $value !important;
    }
  }
}

@include mobile {
  @for $i from 0 through 6 {
    $value: map-get($spacing-values, "#{$i}");

    .mt-#{$i}-mobile {
      margin-top: $value !important;
    }

    .ml-#{$i}-mobile {
      margin-left: $value !important;
    }

    .my-#{$i}-mobile {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    .mx-#{$i}-mobile {
      margin-left: $value !important;
      margin-right: $value !important;
    }

    .py-#{$i}-mobile {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }

    .px-#{$i}-mobile {
      padding-left: $value !important;
      padding-right: $value !important;
    }
  }
}

.fast-spin {
  animation: fa-spin 1s infinite linear;
}

.no-wrap {
  white-space: nowrap;
}

.table.is-borderless td,
.table.is-borderless th {
  border: 0;
}

.dropdown.is-fullwidth {
  display: flex;
  width: 100%;

  .dropdown-trigger {
    width: 100%;

    .button {
      width: 100%;
    }
  }
}

/* A span which should be the same height as an <input>.  Useful for tables
 * with mixed <inputs> and text */
.is-input-height {
  display: inline-block;

  &::first-line {
    line-height: 2.25rem;
  }
}

.filling-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-transparent {
  background-color: transparent !important;
}

.tags {
  tag-label {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

// Bulma's icon-text uses flex-wrap: wrap. This sometimes causes undesireable icon locations
// when the text ends up multiline. This is a version that keeps the icon inline with text
.icon-text-inline {
  .icon {
    height: inherit;
    width: inherit;
    display: inline;

    &:not(:last-child) {
      margin-right: $icon-text-spacing;
    }

    &:not(:first-child) {
      margin-left: $icon-text-spacing;
    }
  }
}

@include mobile {
  .tabs li.is-active-mobile-only a {
    color: $tabs-link-active-color;
    background-color: $tabs-boxed-link-active-background-color;
    border-color: $tabs-boxed-link-active-border-color;
    border-bottom-color: $tabs-boxed-link-active-border-bottom-color !important;
  }

  .is-small-mobile {
    font-size: $size-7 !important;
  }

  .button.is-small-mobile {
    font-size: $size-7;
    height: 2rem;
    line-height: 1.25;
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
}

.is-selectable {
  cursor: text !important;
}

.notification {
  border: 1px solid white;
}

/* More spacious table; opposite of .table.is-narrow */
.table.is-spacious td,
.table.is-spacious th {
  padding: 0.75em 1em;
}

@include desktop {
  .mb-5-desktop {
    margin-bottom: map-get($spacing-values, "5") !important;
  }
}

a.underline-on-hover {
  &:hover {
    text-decoration: underline !important;
  }
}
