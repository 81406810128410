html[data-view="ingredient_allergens#index_for_recipe"],
html[data-view="ingredient_allergens#index_for_user"] {
  #floating-allergen-list-preview {
    display: flex;
    justify-content: flex-end;

    position: sticky;
    top: 8px;
    margin-bottom: 8px;
    z-index: 5;

    &:not(.is-active) {
      #floating-allergen-list {
        display: none;
      }
    }

    #floating-allergen-list {
      border-color: $grey-dark;
    }
  }

  .allergen-list-preview {
    background-color: $white;
    border: 1px solid $black;
    padding: 0.5em;
    text-transform: uppercase;

    p:not(last-child) {
      margin-bottom: 0.5rem;
    }
  }

  #allergen-edit-table {
    @include until($desktop) {
      tbody {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      td,
      th {
        border: none;
      }

      tbody > tr {
        border: 1px solid $grey-light;
        border-radius: $radius-large;
      }
    }

    @include mobile {
      td {
        display: flex;
        flex-wrap: wrap;
      }

      td > * {
        flex-basis: 100%;
      }

      .row-heading {
        display: flex;
      }

      .ingredient-allergens {
        margin-top: 5px;
      }

      .icon.expanded {
        transform: rotate(90deg);
      }

      .expand-row-toggle {
        flex-grow: 1;
        text-align: right;
      }
    }

    @include tablet {
      tr {
        display: flex;

        th:nth-child(1) {
          flex-basis: 35%;
        }

        th:nth-child(2) {
          flex-basis: 65%;
        }

        td:nth-child(1) {
          flex-basis: 35%;
        }

        td:nth-child(2) {
          flex-basis: 65%;
        }
      }
    }
  }
}
