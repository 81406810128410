/* Approach and implementation by Chris Coyier,
   https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
*/

div[data-growing-textarea] {
  /* easy way to plop the elements on top of each other and have them both sized based on
     the tallest one's height */
  display: grid;

  &::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Identical styling required!! */
    font: inherit;
    padding: calc(0.75em - 1px);
    border: 1px solid transparent;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
    background-color: red;
  }

  & > textarea {
    /* Allow resizing, but it disables auto-resizing */
    /* resize: none; */

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }

  & > textarea,
  &::after {
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
}
