/* This file defines/alters Bulma's initial variables.  It is included in "index.scss"
 * to give us a configured Bulma across the site. */
@charset "utf-8";
@use "sass:meta";

/* don't allow multiple inclusion: creates hard-to-diagnose overwriting of
 * derived variables. */
@if variable-exists("bulma-config-included") {
  @error "bulma_config.scss included more than once";
}
$bulma-config-included: true;

@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// Default 769px
$tablet: 768px;

$spacing-values: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 0.75rem,
  "4": 1rem,
  "5": 1.5rem,
  "6": 3rem,
) !default;

$primary: #00a734;
$secondary: #3eaad6;
$light: #f3f7fb;
$dark: #408abf;
$info: $dark;
$success: $primary;
$warning: #ffc800;
$danger: #e14c4c; /* Labeled wrong in UI Kit */
$alt1: #342053;
$alt2: #d0a685;

$white-bis: #f9fafa;
$white-ter: #f2f7fb;
$grey-lighter: #e7ecef;
$grey-light: #c7cacd;
$grey: #6c6f7c;
$grey-dark: #828588;
$grey-darker: #3f4a56;
$black-ter: #313541;
$black-bis: #312929;

$red: $danger;
$green: $primary;
$orange: $warning;

$text: #16181e;
$title-color: $text; /* defaults to text-strong */
$subtitle-color: $text;

$link: $dark;
$link-hover: darken($link, 10%);

$input-shadow: none;
$input-icon-color: $grey-light;
$input-focus-border-color: #6dbcdf;
$input-border-color: $grey-light;
$input-radius: 3px;

$strong-color: $black;
$strong-weight: 700;

$tabs-link-active-color: $dark;

/* Boxed tab borders: non-palette, derived "shadow" variant of the in-app background color */
$tabs-border-bottom-color: rgb(219, 219, 219);
$tabs-boxed-link-active-border-color: $tabs-border-bottom-color;

$navbar-height: 4rem;
$navbar-padding-vertical: 1.5rem;

$fullhd-enabled: true;
$widescreen-enabled: true;

$family-sans-serif:
  "Lato",
  BlinkMacSystemFont,
  -apple-system,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;

$family-secondary: "Merriweather", Georgia, Constantia, "Lucida Bright", Lucidabright,
  "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", serif;

$family-monospace: Monaco, Consolas, "Lucida Console", monospace;

@import "~bulma/sass/utilities/derived-variables";

$box-shadow:
  0 0.5em 1em -0.125em rgba($scheme-invert, 0.1),
  0 0px 0 1px rgba($scheme-invert, 0.02) !default;

$box-shadow-sharp:
  0 0.5em 1em -0.125em rgba($scheme-invert, 0.2),
  0 0px 0 1px rgba($scheme-invert, 0.04) !default;

$input-focus-box-shadow-size: 0;
