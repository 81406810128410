.nutrition-table {
  font-family: Arial, "Helvetica Regular", Helvetica, "Helvetica W01 Roman", sans-serif;
  border: 1px solid;
  color: black;
  background-color: white;
  -webkit-print-color-adjust: exact;
}

#nutritionfactstable {
  padding: 4px;
  width: 227px;
  line-height: 12px;
  box-sizing: content-box;
}

.nutrition-heading {
  font-size: 27px;
  line-height: 100%;
}

.nutrition-table .is-bold,
.nutrition-heading,
.servingsize,
.dailyvalue,
.new-calories {
  font-family: "Arial Black", "Helvetica Black", "Helvetica W01 Blk", Arial, Helvetica, sans-serif;
}

.nutrition-table .is-not-bold {
  font-family: Arial, "Helvetica Regular", Helvetica, "Helvetica W01 Roman", sans-serif;
}

.separator {
  padding-top: 2.66px;
  margin-bottom: 2.66px;
  border-bottom: 4px solid;
  width: 100%;
}

.thickseparator {
  padding-top: 2.66px;
  margin-bottom: 2.66px;
  border-bottom: 10px solid;
}

.hairlineseparator {
  margin-bottom: 2.66px;
  padding-top: 2.66px;
  border-bottom: 1px solid;
}

.subsubhairlineseparator {
  padding-top: 2.66px;
  margin-bottom: 2.66px;
  margin-left: 20px;
  padding-left: 20px;
  border-bottom: 1px solid;
}

.servingsize {
  padding-top: 1px;
  font-size: 14px;
  line-height: 16px;
}

.nutrient {
  font-size: 11px;
  line-height: 12px;
}

.subgroup {
  padding-left: 10px;
}

.subsubgroup {
  padding-left: 20px;
}

.new-calories {
  font-size: 22px;
  line-height: 24px;
}

.calories {
  font-size: 31px;
  position: relative;
  bottom: 3px;
}

.dailyvalue {
  font-size: 10px;
}

.asterisksection-new-vertical,
.added-sugars-section-new-vertical {
  font-size: 8px;
  padding-top: 1px;
  line-height: 10px;
  text-align: justify;
}

.asterisk {
  width: 5px;
}

.asterisk_text {
  width: 95%;
}

.preview-dimensions,
.download-preview-dimensions {
  @extend .has-text-grey;
}

#sidebar-preview-dimensions {
  @extend .has-text-centered;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.download-preview-dimensions {
  margin-bottom: 0.8rem;
  font-size: 12px;
}

.download-preview-dimensions,
.dimensions-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.dimensions-content {
  gap: 0.2em;
  margin-right: 0.25em;
}
