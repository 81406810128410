@import "tippy.js/dist/tippy.css";

/* Normal text that has a hint */
abbr .icon[data-hint] {
  font-size: 12px;
  color: $grey;
}

[data-hint],
[data-hint-html] {
  cursor: pointer;
}

.tippy-box[data-theme~="recipal"] {
  font-family: $family-sans-serif;

  background-color: $info;
  color: $white;

  // default is inherit which causes issues when used in .labels for example
  font-weight: 400;

  a {
    color: $white;
    text-decoration: underline;
  }

  .tippy-arrow {
    color: $info;
  }

  .tippy-content {
    a {
      color: $white;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.tippy-box[data-theme~="error"] {
  font-family: $family-sans-serif;

  background-color: $danger;
  color: $white;

  .tippy-arrow {
    color: $danger;
  }

  border: 1px solid $white;
}
