#olark-facade {
  position: fixed;
  bottom: 15px;
  right: 20px;
  line-height: 0px;
  z-index: 999;

  .olark-svg-clip-path {
    height: 0;
    width: 0;
  }

  #olark-facade-button {
    height: 60px;
    width: 60px;
    padding: 0px;
    border: none;
    line-height: 0px;
    cursor: pointer;

    color: #ffffff;
    background-color: #3eaad6;
    clip-path: url(#olark-chat-button-clip-path);

    svg {
      width: 35px;
      height: 50px;
    }
  }

  .spinning-element {
    margin: 0px;
  }
}
