/* Note: Specifying a local font-name, e.g., "Arial" will avoid a download when "Arial" is
 * available, which is most of the type.  Think of these of fallbacks. */
@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  src:
    local("Arial"),
    url("../../fonts/arial/arial.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: bold;
  src:
    local("Arial Bold"),
    url("../../fonts/arial/arial-bold.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: "Arial";
  font-style: italic;
  font-weight: normal;
  src:
    local("Arial Italic"),
    url("../../fonts/arial/arial-italic.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: "Arial";
  font-style: italic;
  font-weight: bold;
  src:
    local("Arial Bold Italic"),
    url("../../fonts/arial/arial-bold-italic.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: "Arial Black";
  font-style: normal;
  font-weight: normal;
  src:
    local("Arial Black"),
    url("../../fonts/arial/arial-black.woff") format("woff");
  font-display: fallback;
}

@font-face {
  font-family: "Arial Narrow";
  font-style: normal;
  font-weight: normal;
  src:
    local("Arial Narrow"),
    url("../../fonts/arial/arial-narrow.woff") format("woff");
  font-display: fallback;
}
