.youtube-video {
  margin-bottom: map-get($spacing-values, "6");

  iframe {
    /* It appears youtubes's embed can generate frames that are shifted by a pixel downward
     * Make sure we always have a bottom outline.
     */
    padding-bottom: 1px;
  }
  outline: 1px solid $grey-lighter;
}

.video-modal .modal-content {
  min-width: 80%;
}

.video-modal .youtube-video {
  margin-bottom: 0;
  /* allow just enough padding to allow the outline */
  padding-bottom: 1px;
}
