/**
  Usage: put div.help-container -> .help within the div.control;
  it will hide the .help until the user focuses the .control,
  for example hiding help text until the focus is in a text field.
  See also: HintHelper#help_shown_on_focus
*/

.control .help-container .help {
  display: none;
}

.control:focus-within .help-container .help {
  display: block;
}
