[data-view="ingredients#show"] {
  .ingredient-show-section {
    margin-top: 30px;

    .section-heading {
      font-weight: $weight-bold;
      font-size: 18px;
      color: darken($grey-darker, 5%);
      display: flex;
      align-items: center;
      letter-spacing: initial;
      margin-bottom: 10px;

      .divider {
        flex-grow: 1;
        height: 1px;
        background-color: $grey-darker;
        margin: 0 10px;

        &:last-child {
          margin-right: 0px;
        }
      }

      .button {
        color: inherit;
      }
    }

    .section-row {
      display: flex;
      justify-content: space-between;

      // left side of row
      & > :first-child {
        white-space: nowrap;
        color: $black;
        font-weight: $weight-medium;
        margin-right: 20px;
      }

      // right side of row
      & > :last-child {
        text-align: right;
      }
    }
  }
}
