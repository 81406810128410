.stripe-form.StripeElement {
  padding: 10px;

  background-color: $input-background-color;
  border: 1px solid $input-border-color;
  border-radius: $input-radius;
}

.StripeElement--focus {
  border-color: $input-focus-border-color !important;
}

.StripeElement--invalid {
  border-color: $danger !important;
}
