/* By default Safai follows the spec on the default alignment of TH elements: center
   It might be the only modern browser to do so, and is usually not what we expect.

   This will let us assume THs align left like TDs by default */

/* bulma explicitly sets `text-align: inherit` on its .table, so we need to target both */
table,
.table {
  th:not([align]) {
    text-align: start;
  }
}
