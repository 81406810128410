$pagination-current-color: $link-invert !default;
$pagination-current-background-color: $link !default;
$pagination-current-border-color: $link !default;

.previous-next-pagination {
  .pagination-next:not([disabled]) {
    background-color: $pagination-current-background-color;
    border-color: $pagination-current-border-color;
    a {
      color: $pagination-current-color;
    }
  }
}
