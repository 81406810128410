.three-panel {
  .sidebar {
    /* This follows the UI kit, but a bit bolder to catch more attention */
    [data-navigation-select] {
      &:not(.is-multiple):not(.is-loading)::after {
        border-color: $white !important;
      }

      select {
        background-color: $info;
        color: $white;
      }
    }

    .subtitle {
      font-size: $size-5;
      margin-top: map-get($map: $spacing-values, $key: "6");
      margin-bottom: map-get($map: $spacing-values, $key: "3");
    }

    h2.subtitle:first-of-type {
      margin-top: 0;
    }

    li {
      margin-bottom: map-get($map: $spacing-values, $key: "2");
      line-height: 1.5em;
      &.is-active a {
        color: black;
        font-weight: bold;
      }
    }
  }

  .quote {
    .ldquo {
      color: $danger;
    }

    blockquote {
      color: $danger;
      font-style: italic;
      font-size: $size-5;
      margin-bottom: $gap;
    }

    .author,
    .company,
    .position {
      text-transform: uppercase;
      font-size: $size-7;
      letter-spacing: 1px;
    }
  }
}
