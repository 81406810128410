.animate__animated.animate__pulse {
  --animate-duration: 0.5s;
}

.arrow-link {
  .icon {
    transition: transform 0.15s ease-out;
    transform: translateX(0);
  }

  &:hover {
    .icon {
      transform: translateX(4px);
    }
  }
}

.rotate-90 {
  transform: rotate(90deg);
}
